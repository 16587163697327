// extracted by mini-css-extract-plugin
export var centeredTitle = "cX_hX";
export var childrenContainer = "cX_hZ";
export var container = "cX_c";
export var dark = "cX_dm";
export var innerContainer = "cX_cx";
export var left = "cX_cF";
export var light = "cX_dl";
export var noBottomPadding = "cX_hS";
export var noRectangle = "cX_hW";
export var noTopPadding = "cX_hT";
export var overflowHidden = "cX_hV";
export var right = "cX_cG";
export var subtitle = "cX_j";
export var textContainer = "cX_b9";
export var textContainerCustomWidth = "cX_hY";