// extracted by mini-css-extract-plugin
export var border1 = "cV_hL";
export var border2 = "cV_hM";
export var border3 = "cV_hN";
export var border4 = "cV_hP";
export var container = "cV_c";
export var dark = "cV_dm";
export var large = "cV_bX";
export var ldsRing = "cV_hQ";
export var light = "cV_dl";
export var medium = "cV_bY";
export var primaryPressed = "cV_hR";
export var xlarge = "cV_hK";