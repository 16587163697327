// extracted by mini-css-extract-plugin
export var button = "L_t";
export var children = "L_b1";
export var disabled = "L_bT";
export var fullWidth = "L_b0";
export var iconWrapper = "L_b3";
export var large = "L_bX";
export var link = "L_bK";
export var loading = "L_bS";
export var loadingContainer = "L_b2";
export var medium = "L_bY";
export var primary = "L_bR";
export var secondary = "L_bV";
export var small = "L_bZ";
export var tertiary = "L_bW";